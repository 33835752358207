<script setup lang="ts">
import { KsIcon } from "@aschehoug/kloss";
const cart = useCartStore();

const auth = useAuthorizeStore();

const sidebar = reactive({
  show: false,
});

function toggleSidebar() {
  sidebar.show = !sidebar.show;
}

const isMobile = useMediaQuery("screen and (max-width: 35rem)");

const scaleModifier = computed(() => (isMobile.value ? 0.8 : 1));
</script>

<template>
  <header class="header">
    <div class="header__content">
      <NuxtLink to="/" class="header__logo" aria-label="Aschehoug logo">
        <IconsToolbarLogo fill="white" aria-hidden="true" />
      </NuxtLink>
      <nav class="header__icons-container">
        <NuxtLink
          :to="
            auth.isAuthenticated
              ? '/minside'
              : `/login?redirectTo=${encodeURIComponent('/minside')}`
          "
          class="header__icon"
          aria-label="min profil"
        >
          <KsIcon
            icon="face-smile"
            variant="solid"
            aria-hidden="true"
            class="icon__graphic"
            :scale="scaleModifier * 1.4"
          />
        </NuxtLink>
        <NuxtLink
          to="/handlekurv"
          class="header__icon"
          :aria-label="
            cart.isEmpty
              ? 'handlekurv'
              : `handlekurv, det finnes ${cart.count} produkter i handlekurven din`
          "
        >
          <KsIcon
            icon="bag-shopping"
            variant="solid"
            aria-hidden="true"
            class="icon__graphic"
            :scale="scaleModifier * 1.4"
          />
          <span
            v-if="!cart.isEmpty"
            class="icon__badge"
            aria-live="polite"
            :aria-label="`Totalt ${cart.count} produkter i handlekurven`"
            v-text="cart.count"
          />
        </NuxtLink>
        <button
          :aria-expanded="sidebar.show"
          aria-haspopup="true"
          class="header__icon"
          aria-label="Åpne sidebar"
          data-testid="open-sidebar-button"
          @click="toggleSidebar"
        >
          <KsIcon
            icon="bars"
            aria-hidden="true"
            class="icon__graphic"
            :scale="scaleModifier * 2.2"
          />
        </button>
        <div v-if="sidebar.show" class="overlay" aria-hidden="true" />
        <LayoutSidebar
          v-if="sidebar.show"
          aria-expanded="true"
          :show="sidebar.show"
          @close-sidebar="toggleSidebar"
        />
      </nav>
    </div>
  </header>
</template>

<style scoped lang="scss">
.header--opacity {
  opacity: 0.9;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(209, 92, 92, 0.8);
}

.header {
  --color--primary: #{$green-30};
  --border: #{$thin-border};
  z-index: 100;
  top: 0;
  position: fixed;
  background-color: var(--color--primary);
  height: 6rem;
  border-bottom: var(--border);
  width: 100vw;

  @media print {
    display: none;
  }

  .header__content {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-inline: auto;
    max-width: min($max-width, calc(100vw - 3rem));
    gap: 1rem;

    .header__logo {
      width: fit-content;

      @media screen and (max-width: $extra-small) {
        > svg {
          width: 9rem;
        }
      }
    }

    .header__icons-container {
      display: flex;
      align-items: center;
      gap: 1.5rem;

      @media screen and (min-width: $extra-small) {
        gap: 2.5rem;
      }

      .header__icon {
        cursor: pointer;
        align-items: center;
        display: flex;
        position: relative;

        .icon__badge {
          position: absolute;
          display: flex;
          justify-content: center;
          align-items: center;
          bottom: -1rem;
          right: 1rem;
          background-color: $yellow-20;
          color: $black;
          font-size: 0.8rem;
          font-weight: 600;
          border-radius: 100%;
          width: 1.75rem;
          height: 1.75rem;
        }

        .icon__graphic {
          color: $white;
        }
      }
    }
  }
}
</style>
