<script setup lang="ts">
import { KsIcon } from "@aschehoug/kloss";

defineProps({
  withMascot: {
    type: String as PropType<string>,
    required: false,
    default: undefined,
  },
  colorTheme: {
    type: String as PropType<string>,
    required: false,
    default: undefined,
  },
});

const currentYear = new Date().getFullYear();
</script>

<template>
  <footer :class="clsx('footer', colorTheme)">
    <img
      v-if="withMascot"
      :src="`${useRuntimeConfig().public.assetPrefix}/images/${withMascot}`"
      aria-hidden="true"
      alt=""
      class="footer__mascot"
    />
    <nav class="footer__container">
      <ul class="footer__menu">
        <li>
          <NuxtLink to="/kundeservice">
            <KsIcon icon="headphones" aria-hidden="true" :scale="1.2" />
            Kundeservice
          </NuxtLink>
        </li>
        <li>
          <NuxtLink to="/brukerinvolvering">
            <KsIcon icon="users" aria-hidden="true" :scale="1.2" />
            Brukerinvolvering
          </NuxtLink>
        </li>
        <li>
          <NuxtLink to="/nyhetsbrev">
            <KsIcon icon="envelope" aria-hidden="true" :scale="1.2" />
            Nyhetsbrev
          </NuxtLink>
        </li>
        <li>
          <NuxtLink to="/kjopsbetingelser">
            <KsIcon icon="receipt" aria-hidden="true" :scale="1.2" />
            Kjøpsbetingelser
          </NuxtLink>
        </li>
        <li>
          <NuxtLink to="/personvern">
            <KsIcon icon="shield-check" aria-hidden="true" :scale="1.2" />
            Personvern og cookies
          </NuxtLink>
        </li>
      </ul>
      <LayoutSocial class="footer__social" />
    </nav>
    <div class="footer__copyrights">
      <p>Aschehoug {{ currentYear }}</p>
      <LayoutSocial class="copyright__social" />
    </div>
  </footer>
</template>

<style scoped lang="scss">
.footer {
  --color--quaternary: #{$green-30};
  --border: #{$thin-border};

  background-color: var(--color--quaternary);
  width: 100vw;
  color: $white;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.8rem;
  max-height: unset;
  height: auto;
  position: relative;
  padding-top: 0.2rem;

  @media screen and (min-width: $tablet) {
    padding-top: 3rem;
    max-height: 13rem;
  }

  &__copyrights {
    width: 100%;
    max-width: $max-width;
    margin: 1rem 2rem;
    padding: 0 1rem;
    display: flex;
    justify-content: space-between;

    p {
      min-width: 12rem;
    }

    .copyright__social {
      display: flex;
      justify-content: flex-end;
      flex-flow: row nowrap;
      align-items: center;
      transform: translate(-0.6rem, -0.4rem);

      @media screen and (min-width: $tablet) {
        display: none;
      }
    }

    @media screen and (min-width: $tablet) {
      margin: 0 0 4rem 0;
      justify-content: center;
    }
  }

  &__mascot {
    position: absolute;
    top: -5.6rem;
  }

  &__container {
    width: 100%;
    max-width: $max-width;
    margin: 0 1rem;
    padding: 2rem 0 0.5rem 0;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    border-bottom: var(--border);

    @media screen and (min-width: $tablet) {
      flex-flow: row nowrap;
      margin: 0 auto;
    }

    .footer__social {
      display: none;
      flex: 1;

      @media screen and (min-width: $tablet) {
        display: flex;
        justify-content: flex-end;
        flex-flow: row nowrap;
        align-items: center;
      }
    }

    .footer__menu {
      list-style: none;
      display: flex;
      flex-flow: column nowrap;
      gap: 1.6rem;
      padding: 0.5rem 0.8rem;
      flex: 6;

      @media screen and (min-width: $tablet) {
        flex-flow: row wrap;
        padding: 1rem 0;
        gap: 2.5rem;
      }

      a {
        text-decoration: none;
        color: $white;
        display: flex;
        gap: 1rem;
        align-items: center;
        font-size: 1rem;

        @media screen and (min-width: $small) {
          gap: 0.6rem;
        }
      }
    }
  }
}
</style>
