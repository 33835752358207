<script setup lang="ts">
import { VueQueryDevtools } from "@tanstack/vue-query-devtools";

const previewMode = usePreviewMode();

const script = previewMode.enabled.value
  ? [
      {
        src: "https://app.crystallize.com/static/frontend-preview-listener.js",
        defer: true,
      },
    ]
  : [];

const { env } = useRuntimeConfig().public;

useHead({
  htmlAttrs: {
    lang: "no",
    prefix: "og: https://ogp.me/ns#",
  },
  script,
  meta:
    env === "production"
      ? []
      : [{ name: "robots", content: "noindex, nofollow" }],
});

useSeoMeta(useAddSeo());

useEventListener(window, "focus", () => {
  useCartStore().refreshRemoteCart();
});
</script>

<template>
  <NuxtLayout>
    <LayoutSkipTo />
    <NuxtPage />
    <VueQueryDevtools />
  </NuxtLayout>
</template>
