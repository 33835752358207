import type {
  SessionUserInfoDto,
  UserOrganization,
} from "~/types/core/external/session";
import {
  TOKEN_DATA_KEY,
  OIDC_DATA_KEY,
  logoutAM,
  userSessionState,
} from "@aschehoug/aschehoug-oidc-client";
import type { HubspotData } from "~/types/hubspot";

export const TOT_STUDENTS_DEFAULT = 1;

type ApiUser = SessionUserInfoDto & {
  selectedOrganization: UserOrganization | undefined;
};

export const useAuthorizeStore = defineStore("authorize", () => {
  const { public: env } = useRuntimeConfig();

  const orgApi = useOrganizationApi();

  const sessionApi = useSessionApi();

  const { time } = useServerTiming();

  const hasConfirmedSelectedOrganization = useCookie(
    "__confirmedOrgSelection",
    {
      sameSite: "lax",
      secure: true,
      default: () => false,
    },
  );

  const totStudents = ref(TOT_STUDENTS_DEFAULT);
  const userData = ref<ApiUser | undefined>(undefined);
  const hubspotData = ref<HubspotData | undefined>(undefined);
  const accessToken = ref<string | undefined>();

  const isAuthenticated = computed(() => !!userData.value);

  async function headers() {
    const client = useOidcClient();
    let token = accessToken.value;

    if (import.meta.client) {
      token ||= client.getAccessToken();
    }

    if (!token) {
      console.warn("No access token");
      return;
    }

    if (isExpired(token)) {
      try {
        const state = await time("refreshAccessToken", () =>
          client.refreshAccessToken(),
        );
        if (state === userSessionState.hasActiveSession) {
          accessToken.value = client.getAccessToken();
          useCookie("__accessToken").value = accessToken.value;
          token = accessToken.value;
        } else {
          logout(true);
          return;
        }
      } catch (e) {
        handleError(e, "", false);
        logout(true);
        return;
      }
    }

    return {
      Authorization: `Bearer ${token}`,
    };
  }

  function logout(local = false) {
    hasConfirmedSelectedOrganization.value = false;
    userData.value = undefined;
    hubspotData.value = undefined;
    totStudents.value = TOT_STUDENTS_DEFAULT;
    useCookie("__accessToken").value = null;
    accessToken.value = undefined;
    localStorage.removeItem(TOKEN_DATA_KEY);
    localStorage.removeItem(OIDC_DATA_KEY);

    useProductPreviewStore().clear();
    usePurchasesStore().clear();

    if (!local) {
      logoutAM(env.urls.core.external, window.location.origin);
    } else {
      navigateTo("/");
    }
  }

  async function fetchUserData() {
    if (!userData.value || !hubspotData.value) {
      await refreshUserData();
    }
  }

  async function refreshUserData() {
    const data = await time("fetchUserData.user", async () =>
      $fetch<{ user?: ApiUser; company?: HubspotData }>("/api/user", {
        headers: await headers(),
      }),
    );

    userData.value = data.user;

    const orgCount = data.user?.schoolOrganizations?.length ?? 0;
    if (orgCount <= 1) {
      hasConfirmedSelectedOrganization.value = true;
    }

    if (data.company) {
      hubspotData.value = data.company;
    } else {
      handleError(
        "No company data",
        "Error fetching company data from Hubspot",
        false,
      );
    }
  }

  async function updateActiveOrganization(newOrg: UserOrganization) {
    const orgNo = newOrg?.number;
    if (orgNo) {
      try {
        await time("setActiveOrganization", () =>
          sessionApi.setSessionAttributes({
            activeOrganization: orgNo,
            activeOrganizationName: newOrg?.name ?? "",
          }),
        );

        await refreshUserData();
      } catch (error) {
        handleError(error, "Error updating active organization");
      }
    }
  }

  const selectedOrganization = computed(
    () => userData.value?.selectedOrganization,
  );

  watch(selectedOrganization, async (newOrg, oldOrg) => {
    const orgNo = newOrg?.number;
    if (orgNo && orgNo !== oldOrg?.number) {
      try {
        const nsr = await time("fetchUserData.organization", () =>
          orgApi.getOrganization(orgNo),
        );

        totStudents.value =
          nsr?.numberOfPupilsOverride || nsr?.numberOfPupils || 1;
      } catch (error) {
        handleError(error, "Error fetching organization", false);
      }
    }
  });

  const isTeacher = computed(
    () => userData.value?.role?.includes("teacher") ?? false,
  );

  return {
    accessToken,
    isTeacher,
    isAuthenticated,
    userData,
    hasConfirmedSelectedOrganization,
    selectedOrganization,
    hubspotData,
    totStudents,
    logout,
    headers,
    fetchUserData,
    refreshUserData,

    async selectOrganization(newOrg: UserOrganization | undefined) {
      const old = userData.value?.selectedOrganization;
      if (newOrg) {
        if (newOrg.number !== old?.number) {
          await updateActiveOrganization(newOrg);
        }

        hasConfirmedSelectedOrganization.value = true;
      }
    },
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAuthorizeStore, import.meta.hot));
}
