import type { Organization } from "~/types/core/ecommerce/organization";

export function useOrganizationApi() {
  const api = useCoreApi("organizations");

  return {
    async getOrganization(organizationNumber: OrganizationNumber) {
      return await api.$fetch<Organization>(api.endpoint(organizationNumber));
    },
  };
}
